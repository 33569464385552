var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fee0936c04033c0374d0bb198cda1cc2b07fcbb9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
    enabled: process.env.NEXT_PUBLIC_SENTRY_ACTIVATED_CLIENT === 'true',
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate:
        Number(process.env.NEXT_PUBLIC_SENTRY_PERF_SAMPLE_RATE_CLIENT) || 0.01,
    sampleRate:
        Number(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE_CLIENT) || 0.1,
});
Sentry.setTag('runtime', 'client');
